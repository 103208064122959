// src/theme.js

import { createTheme } from "@mui/material/styles";

// Define your custom colors and other options
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#ff4081",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
  },
  components: {
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: {
          boxShadow: "none",
          background: "transparent",
          alignContent: "center",
          alignItems: "center",

          "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "-3px 3px 3px black",
          },
        },
        staticTooltipLabel: {
          display: "flex",
          background: "transparent",
          fontSize: "0.9rem",
          color: "white",
          boxShadow: "none",
          whiteSpace: "nowrap",
          minWidth: "120px",
          maxWidth: "300px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          padding: "0 10px",
          justifyContent: "end",
        },

        staticTooltip: {
          background: "transparent",
          margin: 3,
          border: null,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 8,
        },
        containedPrimary: {
          color: "#ffffff",
          backgroundColor: "#1976d2",
          "&:hover": {
            backgroundColor: "#155a9c",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#1976d2",
        },
      },
    },
  },
});

export default theme;
