import React from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../../assets/loading.json";
import notFoundAnimation from "../../assets/not-found.json";

const animations = {
  laoding: loadingAnimation,
  "not-found": notFoundAnimation,
};

const LoadingScreen = ({ type = "laoding" }) => {
  return (
    <div style={styles.container}>
      <Lottie
        animationData={animations[type]}
        loop={true}
        style={styles.animation}
      />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
  },
  animation: {
    width: 200,
    height: 200,
  },
};

export default LoadingScreen;
