import logo from "./logo.svg";
import "./App.css";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorBoundary from "./common/components/ErrorBoundary";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import theme from "./theme";
import LoadingScreen from "./common/components/LoadingScreen";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import HomePage from './features/home/pages/HomePage';

const HomePage = React.lazy(() => import("./features/home/pages/HomePage"));
const Menu = React.lazy(() => import("./features/menu/pages/Menu"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300 * 1000,
      // staleTime: 0,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Resets CSS to maintain consistency across browsers */}
        {/* <GlobalContextProvider> */}
        <Router>
          {/* <Navbar /> */}
          <ErrorBoundary>
            <Suspense fallback={<LoadingScreen />}>
              <Routes>
                {/* Define your routes here, pointing to feature pages */}
                <Route path="/" element={<HomePage />} />
                <Route path="/:guid" element={<Menu />} />
                {/* <Route path="/login" element={<LoginPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/profile" element={<ProfilePage />} /> */}
                {/* Add more routes as needed */}
              </Routes>
            </Suspense>
          </ErrorBoundary>
          {/* <Footer /> */}
        </Router>
        {/* </GlobalContextProvider> */}
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
